$main: #001d3d;
$accent: #ffd166;

.block {
  background-color: $accent;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;

  font-size: 32px;

  @media screen and (min-width: 768px) {
    font-size: 64px;
  }

  &,
  & * {
    color: $main;
  }
}
