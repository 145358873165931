$main: #001d3d;
$accent: #ffd166;
$dot: 8px;

.button {
  font-family: inherit;
  background-color: transparent;
  color: $accent;
  border: 0;
  cursor: pointer;
  font-size: 1.5rem;
  width: 100%;
  text-align: left;
  padding: 1rem 0.5rem;
  border-radius: 2px;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: $dot;
    height: $dot;
    border-radius: 50%;
    background-color: $accent;
    margin-right: 1rem;
    opacity: 0;
    transition: opacity 300ms;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.button.inverted {
  color: $main;

  &::before {
    background-color: $main;
  }
}
