.alert {
  position: absolute;
  bottom: 1rem;
  padding: 2rem;
  text-align: center;
}

.alertText {
  margin: 0;
}
