$main: #001d3d;
$accent: #ffd166;

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;

  background-color: $main;
  color: $accent;
  font-family: "Russo One", sans-serif;

  margin: 0 auto;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
