$main: #001d3d;
$accent: #ffd166;
$shadowWidth: 0.5rem;

.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.block {
  position: absolute;
  background-color: $main;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  min-width: 300px;
  padding: 2rem;
  overflow-y: auto;
  box-shadow: black 0px 20px 30px -10px;
  display: flex;
  align-items: center;
}

.block.inverted {
  background-color: $accent;
  box-shadow: $main 0px 20px 30px -10px;
}

.list {
  list-style-type: none;
  padding: 0;
}
